import { sorters } from '../helpers/converters'
import { createSelector } from 'reselect'

export const everything = state => state.rooms

export const itemsUnsorted = state => everything(state).items

export const items = createSelector(itemsUnsorted, rooms =>
  rooms.slice().sort(sorters.roomsASC)
)

export const mapRoomIdToName = createSelector(items, rooms =>
  rooms.reduce((acc, room) => {
    acc[room.room_id] = room.name
    return acc
  }, {})
)
export const mapRoomIdToRoom = createSelector(items, rooms =>
  rooms.reduce((acc, room) => {
    acc[room.room_id] = room
    return acc
  }, {})
)
export const isLoading = state => everything(state).isLoading

export const history = state => everything(state).history
