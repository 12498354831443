import * as types from './types'

const initialState = {
    items: [],
    history: [],
    isLoading: false,
    isLoaded: false,
    isFailed: false,
}

export const reducer = (state = initialState, action) => {
    const {payload} = action

    switch (action.type) {
        case types.LOAD_REQUEST:
            return {
                ...initialState,
                isLoading: true,
            }

        case types.LOAD_SUCCESS:
            return {
                ...state,
                items: payload.items,
                isLoading: false,
                isLoaded: true,
            }

        case types.LOAD_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFailed: true,
            }

        case types.UPDATE_STATUS_REQUEST:
            const {data} = payload
            return {
                ...state,
                isLoading: false,
                isFailed: true,
                items: state.items.map((it) => {
                    const {status} = data.find(({room_id}) => it.room_id === room_id)
                    if (it.status !== status) {
                        it.status = status
                    }
                    return it
                })
            }
        case types.UPDATE_ROOM_STATUS_REQUEST:
            const {room_id, status} = payload
            return {
                ...state,
                isLoading: false,
                isFailed: false,
                items: state.items.map((it) => {
                    if (it.room_id === room_id) {
                        it.status = status
                    }
                    return it
                })
            }

        case types.GET_ROOM_CHANGES_HISTORY:
            return {
                ...state,
                ...payload,
            }

        case types.RESET:
            return initialState

        default:
            return state
    }
}
