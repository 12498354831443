import React from 'react'
import { callModal, modalTemplates } from '../dynamic-modal'

async function processBlankPrinting({
                                      reservation,
                                      settings,
                                      session,
                                      calculatedItems,
                                    }) {
  const { action } = await callModal(
    modalTemplates.confirm({
      title: 'Проверка ФИО',
      text: [
        'Проверьте, чтобы в брони было корректное ФИО.',
        'Оно должно быть указано в формате «Фамилия, Имя, Отчество (при наличии)».',
        `Текущее ФИО в брони: ${reservation.guest_name}`,
      ],
      buttons: ['Отмена', 'ФИО указано корректно'],
    })
  )

  if (action !== modalTemplates.confirm.actions.confirm) {
    return
  }

  const { legalName: name, legalAddress: address, inn } = settings
  const { lcodeObj, lcodeName } = session
  localStorage.setItem(
    'BLANK_DATA',
    JSON.stringify({
      hotelName: lcodeObj?.pretty_name_ru || lcodeName,
      personData: { name, address, inn },
      reservation,
      calculatedItems,
      breakfasts: reservation.prePayedBreakfast?.filter(({is_complementary}) => !is_complementary) || []
    })
  )

  window.open('/print_blank', '_blank')
}

export const PrintBlankButtonView = ({
                                       component: Component,
                                       reservation,
                                       settings,
                                       session,
                                       calculatedItems,
                                     }) => {
  return (
    <Component
      bsStyle="primary"
      onClick={() =>
        processBlankPrinting({ reservation, settings, session, calculatedItems })
      }
    >Акт оказанных услуг
    </Component>
  )
}
