import React from 'react'
import styled from 'styled-components'

import {Field, Name} from './FieldUtils'

function formatPrice(price) {
    return price.toFixed(2).replace('.', ',')
}

const SummaryTableCell = styled.td`
    padding: 1mm 3mm;
    border: 1px solid black;
    text-align: ${props => props.textAlign || 'center'};
    border: ${props => props.border};
    font-size: 12pt;
`

const StyledSummaryTableRow = styled.tr`
    page-break-inside: avoid;
`

const SummaryTableRow = props => (
    <StyledSummaryTableRow>
        <SummaryTableCell>{props.id}</SummaryTableCell>
        <SummaryTableCell textAlign="left">{props.service}</SummaryTableCell>
        <SummaryTableCell>{props.count}</SummaryTableCell>
        <SummaryTableCell textAlign="left">{props.unit}</SummaryTableCell>
        <SummaryTableCell textAlign="left">
            {formatPrice(props.price)}
        </SummaryTableCell>
        <SummaryTableCell>
            {formatPrice(props.totalPrice)}
        </SummaryTableCell>
    </StyledSummaryTableRow>
)

const SummaryTableFinalRow = props => (
    <StyledSummaryTableRow>
        <SummaryTableCell
            colSpan={5}

            textAlign="right"
            border="none"
        >
            Итого:
            <br/>
            В том числе (НДС)
            <br/>
            Всего с учетом (НДС)
        </SummaryTableCell>
        <SummaryTableCell>
            {formatPrice(props.total)}<br/>
            {formatPrice(props.nds)}<br/>
            {formatPrice(props.total)}
        </SummaryTableCell>
    </StyledSummaryTableRow>
)

const PeriodService = props => (
    <React.Fragment>
        <Field style={{marginTop: '2mm'}}>
            <Name>{props.name} с {props.from} по {props.to}, в категории "{props.rooms}"</Name>
        </Field>
    </React.Fragment>
)

const StyledSummaryTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 10pt;
`

const SummaryTable = props => {
    const {values} = props

    let rowsCounter = 1

    return (
        <StyledSummaryTable>
            <thead>
            <tr>
                <SummaryTableCell>
                    №<br/>
                    п/п
                </SummaryTableCell>
                <SummaryTableCell>Наименование работы (услуги)</SummaryTableCell>
                <SummaryTableCell>Количество</SummaryTableCell>
                <SummaryTableCell>Ед. изм.</SummaryTableCell>
                <SummaryTableCell>Цена, руб.</SummaryTableCell>
                <SummaryTableCell>Сумма, руб.</SummaryTableCell>
            </tr>
            </thead>
            <tbody>
            <SummaryTableRow
                id={rowsCounter++}
                service={
                    <PeriodService
                        name={`Проживание в отеле ${props.hotelName}`}
                        rooms={values.rooms}
                        from={values.formattedCheckIn}
                        to={values.formattedCheckOut}
                    />
                }
                unit="к/с"
                count={values.daysCount}
                price={values.dayAveragePrice}
                totalPrice={values.daysTotalPrice}
            />
            {values.processedServices.map(service => {
                return (
                    <SummaryTableRow
                        id={rowsCounter++}
                        key={rowsCounter}
                        service={service.name}
                        unit="к/с"
                        count={service.quantity}
                        price={service.price}
                        totalPrice={service.totalPrice}
                    />
                )
            })}
            {values.processedBreakfasts.quantity > 0 && <SummaryTableRow
                id={rowsCounter++}
                service={values.processedBreakfasts.name}
                unit="к/с"
                count={values.processedBreakfasts.quantity}
                price={values.processedBreakfasts.price}
                totalPrice={values.processedBreakfasts.totalPrice}
            />}
            {values.processedServiceBreakfasts.quantity > 0 && <SummaryTableRow
                id={rowsCounter++}
                service={values.processedServiceBreakfasts.name}
                unit="к/с"
                count={values.processedServiceBreakfasts.quantity}
                price={values.processedServiceBreakfasts.price}
                totalPrice={values.processedServiceBreakfasts.totalPrice}
            />}
            <SummaryTableFinalRow total={values.totalPrice} nds={values.nds}/>
            </tbody>
        </StyledSummaryTable>
    )
}

export default SummaryTable
