import React, {useEffect} from "react";
import {useActions} from "../../../../common/hooks/use-actions";
import {roomsActions, roomsSelectors} from "../../../../../Modules/rooms";
import {useSelector} from "react-redux";
import {Table} from 'react-bootstrap'
import styled from 'styled-components'
import {FaDesktop, FaMobile} from "react-icons/fa";
import {actionType, room_statuses} from "./room-statuses";
import moment from "moment/moment";

const Block = styled.div`
    max-height: 600px;
    overflow: auto;
`

const TH = styled.th`
    padding: 16px 4px !important;
`

export const History = ({room_id, lcode}) => {
    const {roomChangesHistory} = useActions(roomsActions)
    const list = useSelector(roomsSelectors.history)

    useEffect(() => {
        roomChangesHistory({lcode, room_id})
    }, []);
    return (
        <Block>
            <Table responsive
                   className="table-hover condensed"
                   style={{fontSize: 12, width: '100%'}}>
                <thead>
                <tr>
                    <TH>Время</TH>
                    <TH>Источник</TH>
                    <TH>Статус</TH>
                    <TH>Действие</TH>
                </tr>
                </thead>
                <tbody>
                {list.map(({status, timestamp, author}, index) =>
                    <Row key={index} status={status} timestamp={timestamp} author={author}/>)}

                {list.map(({status, timestamp, author}, index) =>
                    <Row key={index} status={status} timestamp={timestamp} author={author}/>)}

                {list.map(({status, timestamp, author}, index) =>
                    <Row key={index} status={status} timestamp={timestamp} author={author}/>)}
                </tbody>

            </Table>
        </Block>
    )
}

const Row = ({status, timestamp, author}) => {
    const {key, value} = actionType[author] || {}
    return (<tr>
        <td>{moment(timestamp).format("DD.MM.YYYY HH:mm")}</td>
        <td style={{textAlign: "center"}}>
            {key === actionType.mobile_app.key && <FaMobile/>}
            {!key && <FaDesktop/>}
        </td>
        <td>{room_statuses[status].name}</td>
        <td>{value || `Cмена администратором ${author}`}</td>
    </tr>)

}
