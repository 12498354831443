import React, {forwardRef, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {reservationSelectors} from "../../../../Modules/reservation";
import Modal from "react-modal";
import {
    ModalButton,
    ModalButtonWithLoading,
    ModalControls,
    ModalError,
    ModalHR,
    ModalSuccessMessage,
    ModalText,
    ModalTitle
} from "../../../common/ModalParts";
import {LogsAPI} from "../../../../Modules/logs-api";
import {cardAPI} from "../../../../Modules/api/cardApi";
import {doorLocksTypes} from "../../../../Modules/settings/utils";
import {useReactToPrint} from "react-to-print";
import {Pages} from "../../../../PrintPages/common/Pages";
import Cookies from "js-cookie";
import moment from "moment/moment";

const cookie = "CODE_DATA"


const setCookie = (pk, endDate, checkoutTime) => {
    const data = Cookies.getJSON(cookie) || {}
    data[pk] = true
    const expires = moment(endDate + ' ' + checkoutTime).toDate()
    Cookies.set(cookie, data, {expires})
}

const getCookie = (pk) => {
    const data = Cookies.getJSON(cookie) || {}
    return data[pk]
}

export const GetCodeModal = ({isOpen, hideModal}) => {
    const booking_number = useSelector(reservationSelectors.bookingNumber)
    const booking_id = useSelector(reservationSelectors.pk)
    const endDate = useSelector(reservationSelectors.reservationEnd)
    const checkOutTime = useSelector(reservationSelectors.checkOutTime)

    const [isSending, setIsSending] = useState(false)
    const [isGetting, setIsGetting] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [hasError, setHasError] = useState(false)
    const [gotCode, setGotCode] = useState(false)
    const [codes, setCodes] = useState(false)

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });

    useEffect(() => {
        if (isOpen)
            setGotCode(getCookie(booking_id))
    }, [booking_id, isOpen]);

    const ComponentToPrint = forwardRef((props, ref) => (
        <div ref={ref}>
            <Pages>
                <div style={{padding: "20px", display: "flex", gap: "10px"}}>
                    Коды доступа: <div>{codes.map(({passcode}) => <strong
                    key={passcode}>{passcode}<br/></strong>)}</div>
                </div>
            </Pages>
        </div>
    ))

    const showCode = async () => {
        LogsAPI.pressAction('Показать код')
        setIsGetting(true)
        setSuccessMessage(null)
        setHasError(null)
        try {
            const {passcodes} = await cardAPI[doorLocksTypes.ROOMSHARING].getCode({booking_number, booking_id})
            LogsAPI.event(`Код успешно получен (${booking_number})`)
            setCodes(passcodes)
        } catch ({response}) {
            setHasError(response?.data.message ?? 'Неизвестная ошибка')
            LogsAPI.error(`showCode: ${response?.data.message ?? 'Неизвестная ошибка'} (${booking_number})`)
        }
        setIsGetting(false)
    }

    const sendCode = async () => {
        LogsAPI.pressAction('Отправить код')
        setHasError(false)
        setIsSending(true)
        setSuccessMessage(null)
        setCodes(false)
        try {
            await cardAPI[doorLocksTypes.ROOMSHARING].sendCode({booking_number, booking_id})
            setSuccessMessage('Код успешно отправлен!')
            setCookie(booking_id, endDate, checkOutTime)

            LogsAPI.event(`Код успешно отправлен (${booking_number})`)
        } catch ({response}) {
            setHasError(response?.data.message ?? 'Неизвестная ошибка')
            LogsAPI.error(`sendCode: ${response?.data.message ?? 'Неизвестная ошибка'} (${booking_number})`)
        }
        setGotCode(true)
        setIsSending(false)
    }

    return (
        <Modal isOpen={isOpen} style={{content: {width: 540}}}>
            <ModalTitle>Отправка кода от замка</ModalTitle>
            <ModalHR/>
            <ModalText>Нажмите кнопку для отправки кода гостю</ModalText>
            {successMessage && (
                <ModalSuccessMessage>{successMessage}</ModalSuccessMessage>
            )}
            {hasError && <ModalError>{hasError}</ModalError>}
            {codes && <ComponentToPrint ref={printRef}/>}
            <ModalHR/>
            <ModalControls>
                <ModalButton
                    bsStyle="danger"
                    onClick={() => {
                        hideModal()
                        setIsSending(false)
                        setSuccessMessage(null)
                        setHasError(false)
                        setCodes(false)
                        LogsAPI.pressAction('Закрыть диалог получения кода')
                    }}
                >
                    Отмена
                </ModalButton>
                <ModalButtonWithLoading
                    loading={isSending}
                    bsStyle="success"
                    onClick={sendCode}
                >
                    {gotCode ? "Отправить повторно" : "Отправить код"}
                </ModalButtonWithLoading>

                {gotCode && <ModalButtonWithLoading
                    loading={isGetting}
                    bsStyle="warning"
                    onClick={showCode}
                >
                    Показать код
                </ModalButtonWithLoading>}


                {codes && <ModalButton
                    bsStyle="success"
                    onClick={handlePrint}>Печать
                </ModalButton>}

            </ModalControls>
        </Modal>
    )
}
